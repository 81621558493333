import React from 'react'

export default class Footer extends React.Component {

    render() {
        return <div /* style={{marginTop: 20}} */ className="footer">
            <p class="m-0 text-center text-white" >
                Copyright &copy; 2019-2025 <a href="http://www.dayalbagh.org.in/" target="_blank"
                    class="text-white"><u>Ra</u> <u>Dha</u> <u>Sva</u> <u>Aa</u> <u>Mi</u> Satsang Sabha, Dayalbagh, Agra, UP - 282005, INDIA</a>
            </p>
        </div>
    }

}
